<template>
  <div class="col-md-8 col-lg-6 ml-auto mr-auto">
    <form class="form" @submit.prevent="handleSubmit">
      <div class="card card-login">
        <div class="card-header ">
          <div class="card-header ">
            <h3 class="header text-center">
              <translate>Forgot password</translate>
            </h3>
          </div>
        </div>
        <div class="card-body">
          <FormInput
            field="email"
            v-model="email"
            :label="$gettext('Work email')"
            required
          />
          <SubmitButton
            :label="$gettext('Submit')"
            class="btn btn-info btn-block mb-3"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import FormInput from "@/components/FormGroups/FormInput";
import { mapActions, mapGetters } from "vuex";
import SubmitButton from "@/components/SubmitButton";

export default {
  components: { SubmitButton, FormInput },
  data: () => ({
    email: ""
  }),
  computed: {
    ...mapGetters("me", ["lg"])
  },
  methods: {
    ...mapActions("me", ["forgotPassword"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      this.submit(() =>
        this.forgotPassword({ email: this.email, lg: this.lg, client: "ats" })
      ).then(() => this.$router.push({ name: "login" }));
    }
  }
};
</script>
